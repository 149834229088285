.BestSafariSide{
    display: flex;
    justify-content: space-between;
    margin-top: -7px;
    margin-bottom: -7px;
}

.MainBannerImageContainerSecond{
    width: auto; 
    position: relative;
    text-align: center;
}

.TitleExperinceTxt{
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
}

.BestSafariTxtPosition {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.DubaiVisaBannerTxtOne {
    margin: 0px;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #282B2C;
    margin-bottom: 3%;
}

.DubaiVisaDescOneottom {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: auto;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #282B2C;
    bottom: 30px;
    width: 80%;
}

.DubaiVisaDescOne {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #282B2C;
}

.BestInSafariDescOneottom{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: auto;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
    bottom: 30px;
}

.DubaiVisaBottomStrut{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 10px;
    /* background-color: #2196F3; */
    padding: 10px;
}

.VisaInternalHeadTxt{
    width: 100%;
}

.BckColorBlueYatchs{
    background-color: #E8F8FF;
    padding-top: 20px;
    padding-bottom: 20px;
}

.InsideModalBoxDesign{
    /* background-color: red !important; */
    border-radius: 10px;
    border: none !important;
    width: 60%;
    font-family: 'Poppins';
}

.ModalTitleText{
    text-align: center;
}

.TitlImgCrssModl{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TitlImgCrssModl{
    font-size: 26px;
    margin-top: 8px;
    cursor: pointer;
}


.SubmitButton {
    margin-top: 20px;
}

@media(max-width:767px){

    .TitleExperinceTxt{
        text-align: center;
    }

    .ModalTitleText{
        font-size: 18px !important;
    }

    .TitlImgCrssModl{
        font-size: 26px;
        margin-top: 8px;
        cursor: pointer;
    }

    .InsideModalBoxDesign{
        width: 80%;
        padding: 10px !important;
    }

    .DubaiVisaBannerTxtOne{
        font-size: 26px;
    }

    .DubaiVisaDescOneottom{
        bottom: -30px;
        width: 100%;
        font-size: 10px;
    }

    .DubaiVisaDescOne{
        margin-top: 10px;
        width: 95%;
        font-size: 10px;
    }

    .DubaiVisaBottomStrut{
        grid-template-columns: auto auto auto auto;
        gap: 0px;
        padding: 0px;
        padding-bottom: 10px;
    }
}