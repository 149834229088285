.MainSlideComponent {
    background-color: #fff;
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.ReviewImgone img {
    height: 80px;
    margin: 0px;
    max-width: 100%; /* Ensure images scale down */
}

.ReviewImgone h4 {
    margin: 0px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 16px; /* Adjust font size for responsiveness */
}

.ImgStarCommonCls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.ReviewStarImage img {
    margin: 0px;
    width: 20px; /* Adjust star size for smaller screens */
    height: 20px;
}

.ReviewBoxText p {
    color: #000;
    font-size: 12px;
    text-align: start;
    line-height: 1.5; 
}

@media (max-width: 768px) {
    .MainSlideComponent {
        padding: 10px;
        padding-left: 15px;
        padding-right: 35px;
        padding-top: 30px;
        padding-bottom: 30px;
    }


    .ReviewImgone img {
        height: 60px; /* Smaller image size for smaller screens */
    }

    .ReviewImgone h4 {
        font-size: 14px; /* Smaller font size for smaller screens */
        margin-top: 12px;
        margin-bottom: 8px;
    }

    .ReviewStarImage img {
        width: 15px; /* Smaller star size for smaller screens */
        height: 15px;
    }

    .ReviewBoxText p {
        font-size: 11px; /* Smaller font size for text */
    }
}

@media (max-width: 480px) {
    .MainSlideComponent {
        padding: 10px;
        padding-left: 15px;
        padding-right: 35px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .ReviewImgone img {
        height: 50px; /* Smaller image size for very small screens */
    }

    .ReviewImgone h4 {
        font-size: 12px; /* Smaller font size for very small screens */
        margin-top: 12px;
        margin-bottom: 8px;
    }

    .ReviewStarImage img {
        width: 12px; /* Smaller star size for very small screens */
        height: 12px;
    }

    .ReviewBoxText p {
        font-size: 10px; /* Smaller font size for text */
    }
}
