

.highlightImage {
    width: 30vw;

}

/* custom.css */
.PhoneInput {
    --PhoneInput-color--focus: transparent; /* Remove focus outline */
  }
  
  .PhoneInputInput {
    outline: none; /* Remove default outline */
  }
  
  .PhoneInputInput:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
  }
  