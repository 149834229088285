.SafariSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px; 
}

/* .tabs-container {
  display: flex;
  justify-content: space-between;
  background-color: #E8F8FF;
  width: 40%;
  border-radius: 5px;
  height: 65px;
  margin: 20px 0;
}

.tab {
  display: flex;
  background-color: #f1f1f1;
  border: none;
  padding: 20px 50px;
  margin: 0 5px;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #009BDE;
  color: #fff;
}

.tab:hover {
  background-color: #009BDE;
  color: #fff;
}

.tab-icon {
  margin-right: 5px; 
} */


.CruisesCarousel {
  /* width: 100%;
  height: 62vh;
  margin-top: 30px;  */
  /* margin-top: 30px;   */
  width: 100%;
  height: 100%;
  margin-top: 90px;
  display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-image-placeholder {
  width: 80%;
  height: 100%;
  /* background-color: #dcdcdc; */
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  font-size: 1.5rem;
  color: #555;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: center;
}

.title-section {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
    justify-content: center;
}

.titleSection {
  width: 70%;
}


.title-section h2 {
  font-size: 39px;
  color: #000;
}

.title-section p {
  font-size: 1rem;
  color: #666;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  background-color: #e8f8ff;
  width: 100%;
  overflow: hidden;
}

.info-cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background-color: #e6f4ff;
  border-radius: 10px;
  padding-bottom: 16px;
  flex-wrap: nowrap; 
}

.info-card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 30%;
  height: auto; 
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headSec {
  display: flex;
  font-size: 20px; 
  font-weight: 600;
  align-items: center;
  color: #5e6282;
  margin-left: 10px;
  height: auto; 
  overflow: hidden; 
}

.groupImg {
  height: 50px; 
  margin-right: 8px;
}

.descSec {
  display: flex;
  flex: 1;
  text-align: start;
  height: auto;
  margin-left: 10px;
  font-size: 15px; 
  font-weight: 600;
  color: #5e6282;
}

.safari-details-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6vh;
  justify-content: space-between;
  width: 90%;
}

.safari-details {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  /* justify-content: space-around; */
  overflow: hidden;
  width: 25%;
  height: 420px;
  max-height: 500px; 
  margin-bottom: 3vh;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.safari-image {
  width: 78%;
  height: 250px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
  opacity: 0.9;
  transition: transform 0.5s ease-in-out !important;
  object-fit: cover;
}

/* 



.safari-image:hover {
  transform: scale(1.1); 
} */



.descCont{
  display: flex;
  flex-direction: column;
  height: 100px;
  align-content: stretch;
  min-height: 100px;
}

.textHead {
  font-size: 18px;
  display: flex;
  width: 95%;
  height: 60px;
  justify-content: space-between;
  font-weight: 600;
  text-align: start;
  margin: 0;
  align-items: center;
  margin-bottom: 10px;
}

.title{
  display: flex;
  align-items: center;
}

.ico{
  display: flex;
  margin-right: 20px;
}

.icoCont{
  display: flex;
  font-weight: 400;
  margin-right: 10px;
  font-size: 20px;
  align-items: center;
  display: flex;
  flex-direction: row;

}

.price {
  font-size: 14 px;
  font-weight: 400;
  text-align: start;
  display: flex;
  margin: 0;
  color: #8f8e8e;
  /* margin-left: 10px; */

}

.price span{
  display: flex;
  font-weight: 500;
  margin-left: 5px;
}

.btn {
  padding-left: 40px;
  padding-bottom: 16px;
  margin-top: auto; 
}

.safari-details span {
  font-weight: bold;
}

.safari-details button {
  display: flex;
  background-color: #000;
  color: white;
  padding: 12px 20px; 
  width: 140px; 
  border-radius: 7px;
  font-size: 18px; 
  justify-content: center;
  align-items: center;
}

.tours-details-section {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around; 
  width: 100%;
  height: 100%;
  margin: 0 auto; 
}

.tour-title{
  display: flex;
  width: 94%;
  margin-bottom: 5px;
  height: 60px;
  min-height: 50px;
  font-size: 35px;
  font-weight: 600;
  color: #282B2C;
  margin-top: 60px;
  align-items: center;
  font-weight: 600;
  margin-left: 30px;
}

.tour-titl{
  display: flex;
  width: 94%;
  height: 60px;
  min-height: 50px;
  font-size: 35px;
  font-weight: 600;
  color: #282B2C;
  margin-top: 60px;
  align-items: center;
  font-weight: 600;
}


.carousel-img-mobile {
  display: none;
}

.carousel-img {
  display: block;
}


@media(max-width:768px){
  .tour-title{
    display: flex;
    width: 94%;
    margin-bottom: 5px;
    height: 60px;
    min-height: 50px;
    font-size: 25px;
    font-weight: 600;
    color: #282B2C;
    align-items: center;
    font-weight: 500;
  }

  .tour-titl{
    display: flex;
    width: 94%;
    height: 60px;
    min-height: 50px;
    font-size: 23px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #282B2C;
    margin-top: 5px;
    align-items: center;
    font-weight: 600;
  }

  .carousel-img-mobile {
    display: block;
  }

  .carousel-img {
    display: none;
  }
  
}

@media(max-width:951px){
  .tour-title{
    display: flex;
    width: 94%;
    margin-bottom: 5px;
    height: 60px;
    min-height: 50px;
    font-size: 25px;
    font-weight: 600;
    color: #282B2C;
    align-items: center;
    font-weight: 500;
  }

  .tour-titl{
    display: flex;
    width: 94%;
    height: 60px;
    min-height: 50px;
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #282B2C;
    margin-top: 5px;
    align-items: center;
    font-weight: 600;
  }
}


.tour-details {
  position: relative;
  width: 100%;
  height: 477px;
  max-height: 302px;
  max-width: 247px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px auto; 
  transition: transform 0.5s ease-in-out !important;
}


/* .tour-details:hover {
  transform: scale(1.1); 
} */

.tour-details1 {
  position: relative;
  width: 100%;
  height: 460px;
  max-height: 285px;
  max-width: 275px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px auto; 
  transition: transform 0.5s ease-in-out !important;
}


/* .tour-details1:hover {
  transform: scale(1.1); 
} */

.tour-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.tour-image:hover {

}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.overlay-text h3 {
  margin: 0;
  font-size: 1.5em; 
}

.overlay-text p {
  margin: 5px 0 0;
  font-size: 1em; 
}

.tourcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.details-tour {
  display: flex;
  flex: 1;
}

.display-tour {
  display: flex;
  flex: 1;
  font-size: 19px;
  justify-content: flex-start;
  text-align: start;
  align-items: start;
}

.route-tour {
  display: flex;
  width: 47px;
  justify-content: flex-start;
  align-items: start;
}

.arrow-route {
  display: flex;
  width: 31px;
  height: 40px;
}

.price-tour {
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  font-size: 1.4375rem;
  padding: 5px;
  font-weight: 600;

}

.tabs-container {
  display: flex;
  justify-content: space-between;
  background-color: #E8F8FF;
  width: 100%; 
  max-width: 40%; 
  border-radius: 5px;
  height: 70px;
  margin: 20px 0;
}

.tab {
  display: flex;
  flex: 1; 
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 20px 10px; 
  margin: 0 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.tab.active {
  background-color: #009BDE;
  color: #fff;
}

.tab:hover {
  background-color: #009BDE;
  color: #fff;
}

.tab-icon {
  margin-right: 5px; 
}

.activity-container {
  padding: 20px;
  width: 94%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
}

.activity-container .title-cont h2 {
  font-size: 36px;
  color: #333;
}

.activity-container .desc-cont p {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.activity-container .menu-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.activity-container .activity-card {
  border-radius: 10px;
  width: 23%;
  min-height: 600px;
  height: 600px;
  /* margin-bottom: 70px; */
  text-align: center;
}

.activity-container .activity-image {
  width: 100%;
  border-radius: 10px;
  height: auto;
}

.activity-container .activity-details {
  margin-top: 15px;
  
}

.activity-container .activity-details h3 {
  font-size: 20px;
  padding-left: 8px;
  display: flex;
  text-align: start;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  color: #333;
}

.activity-container .activity-tags {
  display: flex;
  justify-content: space-around; 
}

.tag2 {
  border: 2px solid #009BDE;
  display: flex;
  color: #009BDE;
  justify-content: center;
  background-color: #E5F5FB;
  height: 30px;
  align-items: center;
  width: 130px;
  border-radius: 10px;
  padding: 3px;

}

.tag1 {
  border: 2px solid #009BDE;
  display: flex;
  color: #009BDE;
  justify-content: center;
  background-color: #E5F5FB;
  height: 30px;
  align-items: center;
  width: 130px;
  border-radius: 10px;
  padding: 3px;
  
}


.tag1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.tag2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}


.activity-container {
  padding: 20px;
  width: 94%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
}

.activity-container .title-cont h2 {
  font-size: 36px;
  color: #333;
}

.activity-container .desc-cont p {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.activity-container .menu-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.activity-container .activity-card {
  border-radius: 10px;
  width: 23%;
  min-height: 600px;
  height: 600px;
  /* margin-bottom: 70px; */
  text-align: center;
}

.activity-container .activity-image {
  width: 100%;
  border-radius: 10px;
  height: auto;
  max-height: 300px; /* Set a maximum height for mobile view */
}

.activity-container .activity-details {
  margin-top: 15px;
}

.activity-container .activity-details h3 {
  font-size: 20px;
  padding-left: 8px;
  display: flex;
  text-align: start;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  color: #333;
}

.activity-container .activity-tags {
  display: flex;
  justify-content: space-around;
}

.tag2, .tag1 {
  border: 2px solid #009BDE;
  display: flex;
  color: #009BDE;
  justify-content: center;
  background-color: #E5F5FB;
  height: 25px;
  align-items: center;
  width: 106px;
  border-radius: 10px;
  padding: 4px;
  font-size: 14px;
}

.activity-container .activity-tags span {
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 0.875rem; /* 14px */
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: start;
  text-align: start;
  padding-left: 8px;
  color: #555;
}

.activity-container .activity-details p {
  font-size: 16px;
  color: #777;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: start;
  padding-left: 8px;
  text-align: start;
  margin-top: 10px;
}

.activity-container .activity-details button {
  background-color: #009BDE;
  color: #fff;
  border: none;
  display: flex;
  margin-left: 8px;
  align-items: start;
  font-size: 14px;
  justify-content: flex-start;
  padding: 5px 36px;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-family: 'poppins', sans-serif;
  cursor: pointer;
}

.activity-container .activity-details button:hover {
  background-color: #009BDE;
}

@media (max-width: 1200px) {
  .activity-container .activity-card {
    width: 30%;
  }

  .activity-container .title-cont h2 {
    font-size: 34px;
  }

  .activity-container .desc-cont p {
    font-size: 16px;
  }

  .activity-container .activity-details h3 {
    font-size: 20px;
  }

  .activity-container .activity-details p {
    font-size: 16px;
  }

  .activity-container .activity-details button {
    font-size: 14px; 
    cursor: pointer;

    /* padding: 14px 50px; */
  }
}

@media (max-width: 768px) {
  .activity-container .menu-cont {
    flex-direction: column;
    align-items: center;
  }

  .activity-container .activity-card {
    width: 65%;
  }

  .activity-container .title-cont h2 {
    font-size: 30px;
  }

  .activity-container .desc-cont p {
    font-size: 16px;
  }

  .activity-container .activity-details h3 {
    font-size: 20px;
  }

  .activity-container .activity-details p {
    font-size: 16px;
  }

  .activity-container .activity-details button {
    font-size: 14px; 
    cursor: pointer;

    /* padding: 14px 50px; */
  }

  .tour-details {

    width: 90%;
  }


  .tour-details1 {

    width: 90%;
  }
}

@media (max-width: 480px) {
  .activity-container .title-cont h2 {
    font-size: 16px;
  }

  .activity-container .desc-cont p {
    font-size: 12px;
  }

  .activity-container .activity-details h3 {
    font-size: 20px;
  }

  .activity-container .activity-details p {
    font-size: 14px;
  }

  .activity-container .activity-details button {
    font-size: 14px; 
    cursor: pointer;

    /* padding: 12px 40px; */
  }

  .activity-container .activity-image {
    max-height: 200px; 
  }
}


@media (max-width: 1024px) {
  .tabs-container {
    width: 100%; 
    max-width: 60%; 
    height: auto;
  }

  .tab {
    padding: 15px 5px; 
    font-size: 14px; 
  }

  .safari-details-section {
    width: 90%;
    margin-left: 10px;
  }

  .infoContainer{
    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 768px) {
  .tabs-container {
    width: 100%; 
    max-width: 80%; 
  }

  .tab {
    padding: 15px 5px; 
    font-size: 14px; 
  }
}

@media (max-width: 480px) {
  .tabs-container {
    width: 100%;
  }

  .tab {
    padding: 10px 5px; 
    font-size: 12px; 
  }
}


@media (max-width: 768px) {
  .tour-details {
    max-width: 100%;
    margin: 10px 0;
  }


  /* .tour-details:hover {
    transform: scale(0); 
  } */

  .tour-details1 {
    max-width: 100%;
    margin: 10px 0;
  }

  .overlay-text h3 {
    font-size: 1.25em; 
  }

  .overlay-text p {
    font-size: 0.875em; 
  }

  .display-tour {
    font-size:19px;
  }

  .price-tour {
    font-size: 1.125rem; 
  }
}

@media (max-width: 480px) {
  .tour-details {
    max-width: 100%;
    margin: 5px 0; 
  }
  .tour-details1 {
    max-width: 100%;
    margin: 5px 0; 
  }
  .overlay-text h3 {
    font-size: 1em;
  }

  /* .tour-details:hover {
    transform: scale(0); 
  } */

  .overlay-text p {
    font-size: 0.75em; 
  }

  .display-tour {
    font-size: 19px;  }

  .price-tour {
    font-size: 1rem; 
  }
}

@media (max-width: 1200px) {
  .info-card, .safari-details {
    width: 45%;
  }

  .textHead {
    font-size: 22px; 
  }

  .price {
    font-size: 16px; 
  }
}

@media (max-width: 992px) {
  .info-cards {
    flex-wrap: wrap; /* Allows wrapping */
    width: 90%;
  }

  .info-card, .safari-details {
    width: 100%; 
    margin: 10px 0;
  }

  .headSec {
    font-size: 18px; 
  }

  .descSec {
    font-size: 15px;
  }

  .textHead {
    font-size: 20px; 
  }

  .price {
    font-size: 14px; 
  }

  .carousel-image-placeholder {
    font-size: 1rem; 
  }

  .tours-details-section{
    width: 92%;
  }

}

@media (max-width: 768px) {
  .info-cards {
    flex-direction: column; 
  }

  .tours-details-section{
    width: 100%;
  }

  .safari-details-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info-card {
    width: 90%; 
  }
  .safari-details {
    width: 84%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headSec, .descSec, .textHead, .price {
    font-size: 15px;
  }
}

@media (min-height: 768px) {
  .descSec {
    font-size: 20px; 
  }

  .safari-details .textHead {
    font-size: 26px; 
  }

  .safari-details .price {
    font-size: 20px;
  }

  .safari-details button {
    font-size: 20px; 
    padding: 14px 24px;
    width: 160px; 
    cursor: pointer;

  }
}

@media (min-width: 993px) and (max-width: 1233px) {
  .info-cards {
    justify-content: space-between; 
  }

  .info-card, .safari-details {
    width: 31%; 
  }
}



.ActivitesSectionContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
}

.titleSectionText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Poppins',sans-serif;
}