.MainNavclass {
  height: 68px;
  background-color: #ffffff;
  margin-bottom: -80px;
  z-index: 999;
  position: relative;
  /* padding-left: 10px;
  padding-right: 10px; */
  /* border: 1px solid red; */
}
.MainNavOtherClass {
  height: 80px;
  background-color: red;
  margin-bottom: -80px;
  z-index: 999;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  /* border: 1px solid red; */
}

.headerMenuMainContainer {
  position: relative;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid red; */
}

.headerMenuMainContainer ul {
  padding: 0;
  margin: 0;
}
.headerMenu {
  position: relative;
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.headerMenu li {
  margin-right: 32px;
}

.headerMenu li:last-child {
  margin-right: 0; /* No margin on the last item */
}

.headerMenuItem {
  font-weight: bold;
  cursor: pointer;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #36404E;
  padding-left: 15px;
  padding-right: 15px;
}

.headerMenuItemSelected {
  font-weight: bold;
  cursor: pointer;
  /* border: 1px solid red; */
  height: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #36404E;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 200px;
  background-color: white;
  color: #000;
}

.headerMenuItem:hover {
  font-weight: bold;
  cursor: pointer;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.icon {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  background-image: url('../Images/icons/Park.svg');
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: filter 0.3s;
}

li:hover .icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon1 {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  background-image: url('../Images/icons/CarProfile.svg');
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: filter 0.3s;
}

li:hover .icon1 {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon2 {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  background-image: url('../Images/icons/Boat.svg');
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: filter 0.3s;
}

li:hover .icon2 {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon3 {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  background-image: url('../Images/icons/Sailboat.svg');
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: filter 0.3s;
}

li:hover .icon3 {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}



.headerMenuLink {
  text-decoration: none;
  font: 600;
}

.NavContainer {
  display: block;
  display: flex;
  background-color: transparent;
  justify-content: left;
  align-items: left;
  /* padding-top: 20px; */
  height: 100%;
}


.detailedBoxContainer {
  width: 80px;
}
.HeaderLogo {
  width: 170px;
  height: 100%;
  background-size: cover;
  margin-left: 10px;
padding: 10px;
}

.LogoContainer {
  width: 18%;
  /* border: 1px solid red; */
}

.FooterLogoContainer {
  width: 100%;
}

.SearchBocContainer {
  width: 20%;
}

.TextFieldNdIcn {
  display: flex;
  align-items: center;
  height: 35px;
}

.TextMainDesign {
  height: 35px !important;
  padding: 0px !important;
  line-height: 35px !important;
  outline: none;
}

.NavContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MobNavContainer {
  display: none;
  /* border: 1px solid red; */
}

.HeaderMenuBtn {
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding-top: 10px;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid red; */
}

.MobViewFlex {
  display: none;
}


.HeaderMenuBtn {
  display: flex;
  align-items: center;
  background-color: #00BFFF;
  border-radius: 20px; 
  padding: 5px 10px; 
  color: #ffffff; 
  font-weight: bold; 
  font-size: 14px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding-top: 5px;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  margin-right: 20px;
  cursor: pointer; 
  transition: background-color 0.3s; 
}

.HeaderMenuBtn:hover {
  background-color: #0080FF; /* Slightly darker blue on hover */
}

.HeaderMenuBtn img {
  margin-right: 10px; /* Space between the icon and text */
  height: 25px; /* Height of the icon */
  width: 25px; /* Width of the icon */
  color: white;
}



@media only screen and (min-width: 1536px) {
  

}

@media (max-width: 1024px) {
  .MainNavclass {
    /* padding-top: 10px; */
    height: 48px;
  }
 
  .HeaderLogo{
    margin-bottom: 10px;
    padding-top: 0px;
  }
  .headerMenuMainContainer {
    display: none;
  }
  .LogoContainer {
    width: 60%;
  }
  .HeaderLogo {
    width: 170px;
     height: 100%;
  background-size: cover;
  }
  .MobViewFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }
  .MobNavContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: flex-end;
    width: 30%;
  }
  .NavContainer {
    display: none;
  }
}


@media (max-width: 736px) {

  .HeaderLogo {
    padding: 0px;
  }

  .MainNavclass {
    height: 36px ;
  }
}
/* 
@media (max-width: 536px) {

  .MainNavclass {
    height: 26px ;
  }
} */

/* @media (max-width: 366px) {

  .MainNavclass {
    height: 24px ;
  }
} */