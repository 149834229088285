.icon {
    width: 24px; 
    height: 24px; 
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    transition: filter 0.3s, text-decoration 0.3s;
  }
  
  .button:hover .icon {
    filter: invert(41%) sepia(95%) saturate(2065%) hue-rotate(175deg) brightness(99%) contrast(107%);
    text-decoration: underline;
    color: #009BDE;
  }
  

  @media (max-width: 1024px) {

    .icon {
      width: 17px; 
      height: 17px; 
      background-size: contain;
      background-repeat: no-repeat;
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
      transition: filter 0.3s, text-decoration 0.3s;
    }
  }