.MainCardStckedComponent{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
}


.FirstCardLineComponent{
    /* width: 300px; */
    margin: 10px;
}

.CardHoverImageCls{
    width: 280px;
    border-radius: 20px;
    /* width: 450px; */
}

.VisaCardHoverImageCls{
    width: 90%;
    border-radius: 20px;
    width: 450px;
}

.NewComponentCardHome{
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    border-radius: 20px;
    /* border: 1px solid red; */
}

/* .CardHoverone{
    border: 1px solid blue;
} */

.FirstCardLineComponent:hover .CardHoverone{
    transition: all 1s;
    transform-style: preserve-3d;
}

.FirstCardLineComponent:hover .CardHoverText{
    /* position: relative; */
    visibility: visible;
    margin-top: 4px;
    transition: all 1s;
    margin-top: -95px;
    background-color: #ffffffb8;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    
}

.FirstCardLineComponent .CardHoverText{
    position: absolute;
    width: auto;
    margin-top: -95px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    border-radius: 15px;
    visibility: hidden;
    font-size: 14px;
    line-height: 25px;
    padding-bottom: 4px;
    padding-top: 10px;
    font-weight: bold;
}

.MainCardStackedButton{
    display: flex;
    justify-content: center;
}

.ContactUsHomeButtonFilled{
    background-color: #0490DD;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #0490DD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 15px;
    margin-top: 15px;
    transition: ease-in-out 0.4s;
}

.ContactUsHomeButtonFilled:hover{
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border: 1px solid #0490DD;
}


.ShowMoreHomeButtonFilled{
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border: 2px solid #0490DD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-left: 15px;
    margin-top: 15px;
    transition: ease-in-out 0.4s;
}

.ShowMoreHomeButtonFilled:hover{
    background-color: #0490DD;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 2px solid #0490DD;
}

/* New */

.HoverCardBlackBtn{
    background-color: #009BDE;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #009BDE;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 15px;
    margin-top: 15px;
    transition: ease-in-out 0.4s;
}

.HoverCardBlackBtn:hover{
    background-color: #009BDE;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border: 1px solid #009BDE;
    cursor: pointer;
}


.CpmmonBtnGroupFlx{
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
}

.InsideCardHoveButtonNw{
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    color: #009BDE;
    border: 1px solid #009BDE;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 5px;
    margin-top: 10px;
    transition: ease-in-out 0.4s;
}

.InsideCardHoveButtonNw:hover{
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #009BDE;
    border: 1px solid #009BDE;
}

@media(max-width:767px){
    .MainCardStckedComponent{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .ContactUsHomeButtonFilled{
        width: 100%;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .NewComponentCardHome{
        padding-left: 20px;
        padding-right: 20px;
    }

    .VisaCardHoverImageCls{
        width: 100%;
    }
}