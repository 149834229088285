.HomeContactBannerTxtbox{
    /* height: 45px !important; */
    /* background-color: red !important; */
    margin-bottom: 30px !important;
    border-radius: 20px !important;
    padding: 0px !important;
    margin-top: 30px;
    display: flex;
}

.HomeContactTxtField input{
    padding: 10px !important;
    height: 30px;
}

/* custom.css */
.PhoneInput {
    --PhoneInput-color--focus: transparent; /* Remove focus outline */
  }
  
  .PhoneInputInput {
    outline: none; /* Remove default outline */
  }
  
  .PhoneInputInput:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
  }
  