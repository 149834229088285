

.LandingBanner {
    /* border: 1px solid red; */
    width: 100%;
    height: 70vh;
    z-index: 0 !important;
    position: relative;
    /* margin: 10px 10px 1px 10px; */
}

.MobileLandingBanner {
    z-index: 0 !important;
    position: relative;
}

.bannerImage {
    height: 90vh;
    width: 100%;
    background-position: cover;
    /* border: 1px solid red; */
}

.carousel-slide {
    /* border: 1px solid red; */
    width: 100%;
}


.carousel-image {
    /* border: 1px solid red; */
    height: 65vh;
    width: 100%;
    object-fit: cover;
}


@media(max-width:520px){
    .bannerImage{
        height: 75vh;
        width: 100%;
        background-position: cover;
        /* border: 1px solid red; */
    }
}
