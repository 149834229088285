.MainBestExperinceContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 20px;
}

.ImgOverSectionHome:hover{
    transform: scale(1.1);
}

.TitleExperinceTxt{
    width: 80%;
}

.TitleExperinceTxt h1{
    margin: 0px;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
}

.TitleExperinceTxt p {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* .TitleExperinceButton{
    width: 20%;
} */

@media(max-width:767px){
    .TitleExperinceTxt{
        width: 100%;
        text-align: center;
    }

    .TitleExperinceTxt h1{
        width: 92%;
        text-align: center;
        font-size: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .MainBestExperinceContainer{
        flex-direction: column;
        padding-top: 30px;
    }

    .TitleExperinceTxt p{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
    }
}