
.SkeletonMainContianer {
    /* border: 1px solid red; */
    /* margin-left: 8px; */
    display: grid;
    height: 148vh;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 24px 23px;
    margin-top: 10px;
}

.SkeletonMainContianer{
    margin-bottom: 35px;
}

.SkeletonCardContainer{
    margin-bottom: 30px;
    border: 1px solid red;
}

.SkeletonCardInnerOne {
    border: 1px solid red;
    height: 251px !important;
    width: 89%;
    background-color: white;
    border-radius: 10px !important;
    margin-bottom: 15px;
}

.SkeletonCardInnerTwo{
    width: 50% !important;
    height: 16px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.SkeletonCardInnerFour{
    width: 50% !important;
    height: 16px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.SkeletonCardInnerThree {
    /* border: 1px solid red; */ 
    height: 55px !important;
    width: 99%;
    background-color: white;
    border-radius: 10px !important;
    margin-bottom: 15px !important;
    margin-top: 0px !important;
    margin-right: 10px !important;
}

@media(max-width:1000px){
    .SkeletonMainContianer {
        /* border: 1px solid red; */
        /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
        grid-gap: 16px 26px;
        grid-template-columns: repeat(auto-fit, minmax(164px, 1fr))
    }
}

@media screen and (max-width: 767px) {
    .SkeletonMainContianer {
        /* border: 1px solid red; */
        grid-gap: 16px 26px;
        grid-template-columns: repeat(auto-fit, minmax(164px, 1fr))
    }
}


.swiper-button-next:after {
    font-family: swiper-icons;
    /* font-size: var(--swiper-navigation-size); */
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1 !important;
    background: #dfdfdf !important;
    padding: 14px !important;
    border-radius: 41px !important;
    font-size: 14px !important;
    color: #000000 !important;
    margin-left: 15px !important;
    font-weight: bolder !important;
    margin-right: 51px !important;
    transform: translateX(15px);
    width: 100px;
    margin-top: 1px;
    /* padding-top: 29px; */
  }
  
  .swiper-button-prev, .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 87%);
    }
  
  /* .swiper-button-prev, .swiper-button-next {
  position: absolute;
  bottom: var(--swiper-navigation-top-offset, 2%);
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  border-radius: -89px;
  align-items: center;
  justify-content: center;
  } */
  
  .swiper-button-prev:after {
  
    border-radius: 67px !important;
    margin-left: 10px;
    margin-left: 15px;
  }
  
  .SixSkeletonResp{
    display: block !important;
  } 
  
  
  .FourSkeletonResp{
    display: none !important;
  }
  
  .ThreeSkeletonResp{
    display: none !important;
  }
  
  .TwoSkeletonResp{
    display: none !important;
  }
  
  
  @media(max-width:1699px){
    .SixSkeletonResp{
      display: none !important;
    } 
    
    
    .FourSkeletonResp{
      display: block !important;
    }
  
    .ThreeSkeletonResp{
      display: none !important;
    }
  
    .TwoSkeletonResp{
      display: none !important;
    }
    
  }
  
  @media(max-width:1024px){
    .SixSkeletonResp{
      display: none !important;
    } 
    
    
    .FourSkeletonResp{
      display: none !important;
    }
    
    .ThreeSkeletonResp{
      display: block !important;
    }
  
    
    .TwoSkeletonResp{
      display: none !important;
    }
  
    .NoCardsAvailableText {
      font-size: 22px;
      font-weight: bold;
      font-family: 'Poppins';
    }
  
  }
  
  
  @media(max-width:865px){
    .SixSkeletonResp{
      display: none !important;
    } 
    
    
    .FourSkeletonResp{
      display: none !important;
    }
    
    .ThreeSkeletonResp{
      display: block !important;
    }
  
    
    .TwoSkeletonResp{
      display: none !important;
    }
  
    .NoCardsAvailableText {
      font-size: 20px;
      font-weight: bold;
      font-family: 'Poppins';
    }
  
  }
  
  
  
  @media(max-width:767px){
    .SixSkeletonResp{
      display: none !important;
    } 
    
    .FourSkeletonResp{
      display: none !important;
    }
    
    .ThreeSkeletonResp{
      display: none !important;
    }
  
    .TwoSkeletonResp{
      display: block !important;
    }
    .NoCardsAvailableText {
      font-size: 16px;
      font-weight: bold;
      font-family: 'Poppins';
    }
  
  }
  
  .skeleton-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 16px;
    width: 95%;
  }


  .skeleton-containerr {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 16px;
    width: 95%;
  }
  
  .skeleton-stack {
    width: 210px;
  }
  
  .carousel-card {
    max-width: 345px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .mySwiper {
    padding: 20px 0;
    padding-left: 10px;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    gap: 20px;
  }

  .mySwiper1 {
    padding: 20px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }
  
  .swiper-button-next, .swiper-button-prev {
    color: black;
  }
  
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px;
  }
  .skeleton-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .skeleton-stack {
    width: 210px;
  }
  
  .carousel-card {
    /* max-width: 345px; */
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    margin-right: 20px;
  }

 
  .carousel-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .carousel-card-media {
    height: 200px;
  }
  
  .carousel-card-content {
    padding: 16px;
  }

  
  .swiper-button-next, .swiper-button-prev {
    color: black;
  }
  
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px;
  }
  
  @media(max-width: 767px) {
    .skeleton-container {
      justify-content: center;
    }
  }
  
  .tour-detail {
    position: relative;
    width: 100%;
    height: 380px;
    max-height: 302px;
    max-width: 290px;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px auto; 
    transition: transform 0.5s ease-in-out !important;
  }