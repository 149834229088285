

.YachtCarouselPageContainer {
    /* border: 1px solid red; */
    width: 100%;
}

.YachtCarouselPageContainer{
   /* border: 1px solid blue; */
   /* height: 200vh; */
   width: 100%;
}

.YachtCarouselPageHeroSection {
    /* border: 1px solid red; */
    /* width: 100%; */
    /* height: 20vh; */
}


.YachtCarouselPageCarouselSection {
    /* border: 1px solid yellow; */
    /* height: 100vh; */
}

.YachtCarouselRelatedSection {
    /* border: 1px solid green; */
    /* height: 40vh; */
}

.YachtCarouselTextContainer {
    border: 1px solid red;
}

.YachtCarouselRelatedContainer {
    /* border: 1px solid blue; */
}

.luxuryYachtImageCarousel {
    /* width: 100%; */
    height: 60vh;
    width: 99vw;
    object-fit: cover;

}

.CarouselCategoryTitle {
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CarouselTitle {
   font-size: 38px;
   font-weight: 600;
}


.carousel-mobile-placeholder {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    font-size: 1.5rem;
    color: #555;
}