.ContactUsTxtPosition{
    /* position: absolute;
    top: 40%;
    left: 25%;
    transform: translate(-50%, -50%); */
    width: 100%;
    /* background: #000000A6; */
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    margin-left: 25px;
}

.ContactusBannerContent{
    text-align: center;
    width: 500px;
}

.ContactUsBannerTxtOne{
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #262626;
    /* margin-bottom: 3%; */
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0px;
}

.HomeContactTxtField{
    background-color: #fff;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    border-radius: 10px;
}

.HomeContactTxtField .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10px !important;
    padding-bottom: 16px !important;
    font-family: 'Poppins'!important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.HomeContactTxtField .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    color: #000 !important;
    font-family: 'Poppins' !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.HomeContactBannerTxtbox{
    margin-top: 30px;
}

.ContactUsBannerButton{
    background-color: #0490DD;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #0490DD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 15px;
    /* margin-top: 15px; */
    transition: ease-in-out 0.4s;
    width: 100%;
}


.ContactUsBannerButton:hover{
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border: 1px solid #0490DD;
}

.ContactBackgroundimage{
    width: 50%;
}

@media(max-width:767px){
    .ContactUsTxtPosition{
        position: relative;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-left: 0px;
    }

    .ContactBackgroundimage{
        width: 0%;
        display: none;
    }

    .MobViewClass{
        display: none;
    }

    .ContactUsBannerTxtOne{
        font-size: 40px;
    }

    .ContactusBannerContent{
        width: 300px;
    }
}

/* custom.css */
.PhoneInput {
    --PhoneInput-color--focus: transparent; /* Remove focus outline */
  }
  
  .PhoneInputInput {
    outline: none; /* Remove default outline */
  }
  
  .PhoneInputInput:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
  }
  

  /* Add this CSS below your existing CSS */
@media(max-width: 400px) {
    .ContactusBannerContent {
        width: 90%;
    }

    .ContactUsBannerTxtOne {
        font-size: 30px;
    }

    .ContactUsTxtPosition {
        padding: 10px;
        margin-left: 0;
    }

    .HomeContactTxtField {
        padding: 5px;
    }

    .ContactUsBannerButton {
        padding: 8px 20px;
    }
    
    .PhoneInput {
        width: 100%;
    }
    
    .PhoneInputInput {
        width: 100%;
    }

    form .w-full {
        width: 100%;
    }
}
