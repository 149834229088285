
body{
    max-width: 2000px;
    width: 100%;
    /* border: 1px solid red; */
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* background-color: grey; */
    font-family: "Poppins", sans-serif !important;
   overflow-x: hidden;
}