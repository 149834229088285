.slick-next{
   display: none !important;
}

.slick-prev {
    display: none !important;
}

.slick-dots {
    font-size: 16px;
  }
    .slick-dots li button {
    width: 36px; 
    height: 36px;
    margin: 0 5px; 
  }
  
.HomeBannerImageMob {
  /* height: 32vh; */
  margin-top: 100px;
  width: 100%;
  object-fit:cover;
}
  @media (max-width: 760px) {
    .HomeBannerImage {
        width: 100%;
        height: 32vh;
        object-fit:contain;
    }
    
    .slick-dots li button {
        width: 20px; 
        height: 20px;
        margin: 0 3px; 
    }
}
