.BlueWavePatternimageSec img {
    width: 100%;
    margin-bottom: -10px;
    /* margin-top: 20px; */
}

.LuxuryYatchPadSides{
    padding-left: 200px;
    padding-right: 200px;
}

.MrgtpMinusCls{
    margin-top: -200px;
}

.MrgBtmMinus{
    margin-bottom: -70px;
}


.HoverCardBlackBtn{
    background-color: #009BDE;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #009BDE;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 15px;
    margin-top: 15px;
    transition: ease-in-out 0.4s;
}

.HoverCardBlackBtn:hover{
    background-color: #009BDE;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border: 1px solid #009BDE;
    cursor: pointer;
    margin-bottom: 20px;
}



@media(max-width:767px){
    .LuxuryYatchPadSides{
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .MrgtpMinusCls{
        margin-top: 0px;
    }

    .MrgBtmMinus{
        margin-bottom: 0px;
    }
}