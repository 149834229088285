.NewSectiontextNDIcnPlacment{
    /* border: 1px solid red; */
    width: 290px;
    /* background: red; */
    height: 40px;
    position: absolute;
    transform: translateY(-80px);
}

.DsplFlexOnImage{
    display: flex;
    justify-content: space-between;
}

.NewCardSectionImage {
    width: 100%;
    border-radius: 20px;
    opacity: 85%;
    background: #00000073;
    width: 353px;
    height: 300px;

}

.DesignTxtNewSection{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}

.DesignSubTxtNewSection{
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}