.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 440px;
}

.main-container{
    display: flex;
    min-width: 25vw;
}

.title-bookin {
  width: 100%;
  padding: 15px 0;
  border-radius: 10px 10px 0 0;
  background-color: #009BDE;
  font-size: 24px;
  text-align: center;
  color: white;
  font-weight: 600;
}

.booking-details {
  width: 100%;
  padding: 20px 0;
  background-color: white;
}

.book-detail {
  font-size: 18px;
  font-weight: 600;
  min-height: 3vh;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.checkout-form {
  width: 100%;
}

.date-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.from {
  font-size: 16px;
  font-weight: 500;
  flex: 1;
}

.form-group {
  flex: 2;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.submit-button {
  background-color: #009BDE;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #007bb5;
}

/* Responsive Design */
@media (min-width: 300px) {
  .checkout-container {
    max-width: 500px;
  }



  .form-group {
    width: 48%; 
  }

  .form-control {
    width: 80%; 
  }

  .submit-button {
    padding: 14px; 
  }
}

@media (min-width: 768px) {
  .checkout-container {
    max-width: 500px;
  }



  .form-group {
    width: 48%; 
  }

  .form-control {
    width: 80%; 
  }

  .submit-button {
    padding: 14px; 
  }
}

