
.CruisesHomeContainer{
    /* border: 1px solid red; */
    /* height: 200vh; */
    /* margin-top: 30px; */
}

.CruisesCarousel {
    /* border: 1px solid blue; */
    height: 62vh;
}

.CruisesTitleSection {
    /* border: 1px solid green; */
    display: flex;
    background-color: #E8F8FF;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.CruisesTitleSectionn {
  /* border: 1px solid green; */
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}


.CruiseshighlightsSection{
    /* border: 1px solid purple; */
    height: 70vh;
    display: flex;
    flex-direction: row;
}

.highlightsection {
    /* border: 1px solid yellow; */
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.ActivitesCards {
    /* border: 1px solid red; */
     
    width: 200px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    text-align: center;
}


.cardTitle-activity {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: rgb(0, 136, 255);
}

.cardDescription-activity {
    font-size: 12px;
}

.CruisesTitleContainer {
    /* border: 1px solid red; */
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 10px;

}


.CruisesTitleDescriptiveSection{
    /* border: 1px solid green; */
    height: 90%;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
}

.CruisesTitleDescriptionContainer {
    /* border: 1px solid yellow; */
    width: 50%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 10px;
    flex-direction: column;
}

@media (max-width: 1023px) {
  .CruisesTitleDescriptiveSection {
    display: flex;
    flex-direction: column;
  }
}

.CruiseTitl{
  display: flex;
  font-size: 23px;
  width: 100%;
  font-weight: 600;
}

.MapContainer {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  flex-direction: column;
}

.CruisesDescription {
  display: flex;
  margin-top: 0;
  flex: 1;
  flex-direction: column;
    width: 100%;
    font-size: 18px;
    /* border: 1px solid red; */
}

.CruisesDescription p {
  display: flex;
  width: 100%;
  text-align: start;
  font-weight: 700;
  font-size: 20px;
}

/* .formContainer {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0;
  background-color: firebrick;

} */

.CruisesTitleBookContainer {
    /* border: 1px solid blueviolet; */
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-right: 20px;
}

.highlightsectionDetailsSection {
  height: auto; /* Adjusted to allow content to dictate height */
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.highlightsectionDetailsImages {
    /* border: 1px solid yellow; */
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.MoreDetailsSection {
  display: flex;
  flex-direction: column;
  background-color: #E8F8FF;
  overflow: hidden;

  overflow-y: hidden; 
}


.RecommendedSection {
  height: 70vh;
}

.FAQSection {
  min-height: 45vh; 
  display: flex;
  flex-direction: column;
  background-color: #E8F8FF;
  padding: 20px;
  margin-top: 20px;
  overflow-x: hidden;
  margin-bottom: 40px; 
}

.image-collage {
  display: flex;
  width: 100%;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.holder1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  width: 100%;
}

.structure1 {
  display: flex;
  width: 100%;
  background-size: cover; 
  background-position: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  background-repeat: no-repeat;
}

.pic-horizontal {
  display: flex;
  object-fit: cover; 
  width: 90%;
  min-height: 7vh;
}

.pic {
  display: flex;
  width: 46%; 
  margin-top: 10px;
  height: 32vh;
  object-fit: cover;
  overflow: hidden;
  background-repeat: no-repeat;
}

.structure2 {
  display: flex;
  width: 94%;
  background-size: cover; 
  background-position: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  justify-content: space-around;
  background-repeat: no-repeat;
}

.p, .pi {
  display: flex;
  width: 46%; 
  margin-top: 10px;
  height: 32vh;
  object-fit: cover;
  background-repeat: no-repeat;
}

.pp, .pip {
  display: flex;
  width: 76%; 
  /* margin-top: 10px; */
  min-height: 35.5vh;
  object-fit: cover;
  background-repeat: no-repeat;
}

.picture {
  display: flex;
  width: 96%; 
  /* margin-top: 10px; */
  min-height: 71.5vh;
  object-fit: cover;
  background-repeat: no-repeat;
}

.holder2 {
  display: flex;
  flex: 1;
  width: 100%;
}

.structure3 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.structure4 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media (max-width: 800px) {
  .image-collage {
    flex-direction: column;
  }

  .holder1, .holder2 {
    width: 100%;
  }

  .structure1, .structure2, .structure3, .structure4 {
    flex-direction: column;
    align-items: center;
  }

  .pic-horizontal {
    width: 100%;
    min-height: 15vh; /* Adjust as needed */
  }

  .p, .pi, .pp, .pip, .picture {
    width: 90%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    min-height: 20vh; /* Adjust as needed */
    margin-top: 5px; /* Adjust as needed */
  }
}

@media (max-width: 480px) {
  .pic-horizontal {
    min-height: 20vh; /* Adjust as needed */
  }

  .p, .pi, .pp, .pip, .picture {
    width: 100%; /* Adjust as needed */
    min-height: 25vh; /* Adjust as needed */
  }
}


.dinner-cruise {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e0f7ff;
  padding: 40px;
  width: 94%;
  border-radius: 8px;
  flex-wrap: wrap;
}

.dinner-cruise-content {
  flex: 1;
  min-width: 300px;
}

.dinner-cruise-content h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 10px;
}

.dinner-cruise-content p {
  font-size: 16px;
  margin: 0 0 10px;
}

.rating {
  font-size: 14px;
  font-weight: 500;
}

.dinner-cruise-button {
  margin-left: 20px;
  flex-shrink: 0;
}

.dinner-cruise-button button {
  background-color: #009BDE;
  color: #fff;
  border: none;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.dinner-cruise-button button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .dinner-cruise {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    align-items: flex-start;
  }

  .dinner-cruise-button {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .structure2 {
    display: flex;
    flex-direction: column;
    width: 94%;
    background-size: cover; 
    background-position: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    justify-content: space-around;
    background-repeat: no-repeat;
  }
  
}

@media (max-width: 1200px) {
  .pic, .pi, .p, .pip, .pp, .picture {
    width: 100%; 
  }
}

@media (max-width: 992px) {
  .holder1, .holder2 {
    flex-direction: column; 
    width: 100%;
  }
}

@media (max-width: 768px) {
  .structure1, .structure2, .structure3, .structure4 {
    flex-direction: column; 
    align-items: center;
    justify-content: space-between;
  }

  .pic, .pi, .p, .pip, .pp, .picture {
    width: 100%;
    height: auto; 
  }
}

@media (max-width: 576px) {
  .pic-horizontal {
    width: 100%; 
    min-height: auto;
  }

  .pic, .pi, .p, .pip, .pp, .picture {
    width: 100%;
    height: auto;
    margin-top: 2px;
  }
}

.Mapsection {
  position: relative;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #E8F8FF;
  margin-top: 0; /* Reset margin-top */
  top: 200px; /* Maintain a fixed distance from the top */
}




/* Adjustments for responsiveness */
@media (max-width: 768px) {
  .FAQSection,
  .Mapsection {
    height: auto; /* Adjusted height for smaller screens */
    padding: 10px;
    overflow-x: hidden;
    margin-top: 40px; /* Increased margin for better spacing on smaller screens */
    top: 0; /* Reset top position for smaller screens */
  }
}

.ContactSection {
  /* border: 1px solid blue; */
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ContactInformation {
  font-size: 18px;
  /* margin-left: 7vw; */
  font-weight: 700;
  color: black;
}

.emailInformation {
  font-size: 13px;
  /* margin-left: 7vw; */
}

.phoneInformation {
  font-size: 13px;
  /* margin-left: 7vw; */
}

.highlightsectionTitle {
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  font-weight: 600;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .highlightsectionListContainer {
    font-size: 12px; 
  }

  .highlightsectionTitle {
    font-size: 18px; 
  }
}

.reviewUserImage {
    height: 200px;
    width: 300px;
}


.swiperCruiseImage {
    object-fit: contain;
    /* height: 100%;
    width: 100%; */
}

.reviewMainContainer {
    /* border: 1px solid red; */
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewCardContainer {
  border: 1px solid #e2e2e25e;
    /* height: 70vh; */
    width: 83%;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 4px 14px 3px #e2e2e25e;
    /* padding-top: 10px; */
    padding-bottom: 15px;
    /* background: red; */
    padding-right: 50px;
    margin-left: -10px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.reviewCardLeftSection {
    /* border: 1px solid green; */
    /* width: 45%; */
    /* align-items: center; */
    padding: 30px;   
    height: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.reviewCardRightSection {
    /* border: 1px solid black; */
    /* width: 45%; */
    padding: 30px;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reviewCardLeftTopImageSection {
    /* border: 1px solid red; */
    width: 70%;
    height: 25%;
    display: flex;
    align-items: center;
}

.reviewCardInformation {
  margin-top: 10px;
  font-family: 'Montserrat',sans-serif;
  font-weight: bold;
}
.reviewCardLeftSectionHero {
    /* border: 1px solid red; */
    /* justify-content: flex-start; */
    width: 70%;
    height: 30%;
    display: flex;
    align-items: end;
}

.reviewCardLeftDescription {
    /* border: 1px solid red; */
    /* width: 96%; */
    /* height: 21%; */
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.reviewCardContactUs {
    /* border: 1px solid red; */
    width: 70%;
    height: 10%;
}

.heroContentText {
    font-size: 28px;
    font-family: 'Montserrat',sans-serif;
}

.customerReviewIntro {
    /* font-size: 34px; */
    font-family: 'Montserrat',sans-serif;

}

.reviewCardName{
  font-family: 'Montserrat',sans-serif;
  margin-bottom: 10px;
  font-size: 24px;
}

.customerReviewLink {
    /* font-size: 34px; */
    /* text-decoration: underline; */
    font-family: 'Montserrat',sans-serif;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
}


.blue-cirle-div {
  /* margin: 0 auto; */
  /* width: 50%; */
  /* position: absolute; */
    background: none repeat scroll 0 0 #3f47cc;
    border-radius: 110px;
    display: block;
    height: 106px;
    width: 110px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 18%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}


.forsomeText {
    font-size: 96px;
    color: white;
}


.mySwiperReview  {
    width: 300px ;
    height: 423px  ;
  }
 
  .mySwiperReview .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  .mySwiperReview .swiper-slide:nth-child(1n) {
    background-color: white;
    box-shadow: 1px 4px 14px 3px #e2e2e25e;
    /* border: 1px solid #e2e2e25e; */
    color: black;
  }
  
  .mySwiperReview .swipecardContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mySwiperReview .swiper-slide:nth-child(2n) {
    
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(3n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(4n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(5n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(6n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(7n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(8n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(9n) {
    background-color: white;
  }
  
  .mySwiperReview .swiper-slide:nth-child(10n) {
    background-color: white;
  }
  


  .reviewCardTextContainer {
    /* border: 1px solid red; */
    height: 30%;
    width: 80%;
  }

  .reviewCardMainContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 90%;
   justify-content: center;
   width:300px;
   /* border: 1px solid red; */
}

  .reviewCardImageContainer {
    border: 1px solid red;
    /* height: 50%;
    width: 100%; */
    /* display: flex; */
    /* justify-content: center; */
  }

  .reviewCardTextContainer {
    /* border: 1px solid red; */
    height: 30%;
    width: 85%;
    text-align: center;
  }
  .reviewCardText {
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  .reviewUserImage {
    border-radius: 110px;
    height: 145px;
    width: 155px;
    background-image: cover;
    object-fit: cover;

  }

  @media(max-width:1024px){
    .reviewCardContainer {
      border: 1px solid #e2e2e25e;
      /* height: 70vh; */
      width: 85vw;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 4px 14px 3px #e2e2e25e;
      /* padding-top: 10px; */
      padding-bottom: 15px;
  }

  .heroContentText {
    font-size: 26px;
    font-family: 'Montserrat',sans-serif;
  }
  
  .mySwiperReview {
    width: 360px;
    height: 420px;
  }

}


@media(max-width:768px){
  .mySwiperReview {
    width: 300px;
    height: 420px;
  }
}

@media(max-width:767px){
  .blue-cirle-div {
    /* margin: 0 auto; */
    /* width: 50%; */
    /* position: absolute; */
    background: none repeat scroll 0 0 #3f47cc;
    border-radius: 110px;
    display: block;
    height: 75px;
    width: 80px;
  }

  .forsomeText {
    font-size: 70px;
    color: white;
  }

  .reviewCardContainer {
   /* background-color: red; */
    flex-direction: column;
    width: 90%;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .heroContentText {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  .reviewCardLeftSectionHero {
    /* border: 1px solid red; */
    /* justify-content: flex-start; */
    width: 100%;
    height: auto;
  }

  .reviewCardLeftSection {
    /* border: 1px solid green; */
    /* width: 45%; */
    /* align-items: center; */
    padding: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.reviewCardLeftDescription {
  /* border: 1px solid red; */
  width: 100%;
  height: auto;
}

.mySwiperReview {
  width: 260px;
  height: 440px;
}

.reviewCardRightSection {
  /* border: 1px solid black; */
  width: 100%;
  padding: 15px;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 45px !important;
}

.reviewCardTextContainer {
  /* border: 1px solid red; */
  height: 100%;
  width: 85%;
  text-align: center;
}





}

.detail{
  display: flex;
  font-size: 15px;
  margin-bottom: 15px;
}



/* Card.css */

.card-container {
  display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    padding: 14px;
    /* border: 1px solid red; */
    margin-top: 20px;
}

.card-container p{
  display: flex;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
}

.card-hold {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; 
  padding: 14px;
}

.card {
  flex: 1; 
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 420px;
  width: 100%; 
  height: auto;
  font-size: 14px;
  box-sizing: border-box;
  transition: transform 0.2s;
}

@media (max-width: 768px) {
  .card {
    flex-basis: 100%; 
    max-width: 100%;
  }
}

.cardv {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  background-color: white;
  font-size: 14px;
}


.cardv h2,
.card h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

.cardv ul,
.card ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.cardv li,
.card li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.cardv .icon,
.card .icon {
  margin-right: 10px;
}

.cruiseDataImage {
  height: 300px;
  /* width:200px; */
}

@media (max-width: 768px) {
  .card,
  .cardv {
    max-width: 100%;
  }
}

.highlightsectionItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.titl{
  display: flex;
  min-width: 4vw;
}

.val{
  display: flex;
  width: 100%;
  min-height:3.6vh;
  align-items: center;
  /* padding-left: 30px; */
}

.star{
margin-right: 8px;
}

.highlightsectionListContainer {
  display: flex;
  flex-direction: column;
  font-size: 14px; 
  padding: 5px;
  margin-left: 10px;
  justify-content: flex-start; 
}


.highlightsectionContainer {
  display: flex;
  flex-direction: column;
  width: 100%; 
}



.cartCardImage {
  height: 40px;
  width: 40px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.RecomemdedProductsContainer {
  display: flex;
  width: 100%;
  /* border: 1px solid red; */
  height: 100%;
  justify-content: center;
  align-items: center;
}

.SimilarProcessContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  height: 100%;
}

.RecommendedProductsTitle {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}


@media (max-width: 1024px) {
  .CruisesTitleSection {
      font-size: 23px;
  }
  .CruisesDescription {
      font-size: 14px;
  }
  .cardTitle-activity {
      font-size: 13px;
  }
  .cardDescription-activity {
      font-size: 8px;
  }
  .highlightsectionTitle {
      font-size: 16px;
  }
  .reviewCardName {
      font-size: 22px;
  }
  .reviewCardText {
      font-size: 14px;
  }
}

@media (max-width: 768px) {
  .CruisesTitleSection {
      font-size: 16px;
  }
  .CruisesDescription {
      font-size: 12px;
  }
  .cardTitle-activity {
      font-size: 12px;
  }
  .cardDescription-activity {
      font-size: 10px;
  }
  .highlightsectionTitle {
      font-size: 14px;
  }
  .reviewCardName {
      font-size: 14px;
  }
  .reviewCardText {
      font-size: 10px;
  }
  .heroContentText {
      font-size: 18px;
  }
}

@media (max-width: 480px) {
  .CruisesTitleSection {
      font-size: 18px;
  }
  .CruisesDescription {
      font-size: 12px;
  }
  .cardTitle-activity {
      font-size: 10px;
  }
  .cardDescription-activity {
      font-size: 10px;
  }
  .highlightsectionTitle {
      font-size: 14px;
  }
  .reviewCardName {
      font-size: 14px;
  }
  .reviewCardText {
      font-size: 10px;
  }
  .heroContentText {
      font-size: 18px;
  }
  .forsomeText {
      font-size: 50px;
  }
  .reviewCardContainer {
      padding: 10px;
      margin-left: 0;
      margin-right: 0;
  }
  .blue-cirle-div {
      height: 60px;
      width: 60px;
  }
}

@media (max-width: 320px) {
  .CruisesTitleSection {
      font-size: 18px;
  }
  .CruisesDescription {
      font-size: 14px;
  }
  .cardTitle-activity {
      font-size: 10px;
  }
  .cardDescription-activity {
      font-size: 8px;
  }
  .highlightsectionTitle {
      font-size: 16px;
  }
  .reviewCardName {
      font-size: 16px;
  }
  .reviewCardText {
      font-size: 8px;
  }
  .heroContentText {
      font-size: 18px;
  }
  .forsomeText {
      font-size: 40px;
  }
  .reviewCardContainer {
      padding: 5px;
      margin-left: 0;
      margin-right: 0;
  }
  .blue-cirle-div {
      height: 50px;
      width: 50px;
  }
}

/* custom.css */
.PhoneInput {
  --PhoneInput-color--focus: transparent; /* Remove focus outline */
}

.PhoneInputInput {
  outline: none; /* Remove default outline */
}

.PhoneInputInput:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: none; /* Remove box-shadow on focus */
}
