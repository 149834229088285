.MainFooterClass {
    height: auto;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-left: 40px;
}

.SubTopSectionFooter {
    display: flex;
    justify-content: end;
    align-items: center;
}

.FooterSubtxtDesc {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #000000;
    margin-top: 15px;
    margin-right: 40px;
}

.subscribe-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
}

.email-input {
    border: none;
    padding: 10px;
    font-size: 16px;
    flex: 1;
    outline: none;
}

.email-input::placeholder {
    color: #7a7a7a;
}

.subscribe-button {
    background-color: #0A8FC8;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    border-top: 1px solid #0A8FC8;
    color: #000000;
}

.company-name {
    font-size: 16px;
    flex: 1;
}

.contact-details {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.detail {
    display: flex;
    align-items: center;
}

.detail i {
    color: #007bff;
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.detail span {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.subscribe-button:hover {
    background-color: #0056b3;
}

.MainMiddleSectionFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
}

.FooterLogoSection {
    width: 20%;
}

.FooterLogoSection .TitleExperinceTxtSecFour {
    text-align: start;
}

.FooterLogoSection .TitleExperinceTxtSecFour {
    color: black;
}

.MiddleAllPagesSection {
    display: flex;
    margin-right: 30px;
}

.MiddlePagesLinkSection {
    margin-right: 7%;
}

.MiddlePagesLinkSection a {
    font-family: 'Poppins';
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 20px;
}

.BottomSubDectionFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    margin-right: 4%;
}

.NewHeadingSectnFooter a {
    color: #262626;
    font-size: 18px;
    padding-bottom: 20px;
}

.NewHeadingSectnFooter {
    padding-bottom: 20px;
}

.FooterOtherElements {
    padding-bottom: 20px;
    color: #262626;
}

@media(max-width: 767px) {
    .company-name {
        font-size: 14px;
        flex: 1 1;
        font-weight: 600;
    }

    .contact-info {
        width: auto;
        padding-top: 30px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contact-details {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .BottomSubDectionFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .FooterSubtxtDesc {
        font-size: 16px;
        margin-right: 0;
        text-align: center;
    }

    .MainFooterClass {
        padding-right: 15px;
        padding-left: 15px;
    }

    .MainMiddleSectionFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .FooterLogoSection {
        width: 100%;
        text-align: center;
    }

    .MiddleAllPagesSection {
        width: 100%;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 0;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        text-align: center;
    }

    .MiddlePagesLinkSection {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }

    .MiddlePagesLinkSection a {
        display: block;
        margin-bottom: 10px;
    }
}
