
.BestInCurseContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.SubHeadingSectionFive{
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
}
.TitleExperinceTxtSecFour{
    text-align: center;
    width: 68%;
}
.TitleExperinceTxtSecFour h2{
    text-align: center;
    width: 100%;
    margin: 0px;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    color: #333;
}

.MainSectionGridContent{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 10px;
    /* background-color: #2196F3; */
    padding: 10px;
    /* width: 100%; */
}

.CardImageOne{
    width: 100%;
    text-decoration:none ;
    
}

.CardImageOne img{
    width: 100%;
}

.CruseTextDescSectionFive{
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000;
}


.buttonViewAllButton {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}


.ViewMoreButton{
    background-color: #0490DD;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #0490DD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 15px;
    transition: ease-in-out 0.4s;
}


@media(max-width:767px){
    .TitleExperinceTxtSecFour{
        width: 95%;
    }

    .BestInCurseContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .MainSectionGridContent{
        grid-template-columns: 46% 46%;
        width: 100%;
        grid-gap: 15px 15px;
        margin-left: 10px;
    }
}
