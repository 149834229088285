

.FAQSectionMainContainer{
    /* border: 1px solid red; */
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    margin-top: 10px;
}

.FAQSectionTitleSection {
   /* border: 1px solid red; */
   /* height: 10vh; */
   width: 87%;
   display: flex;
   /* justify-content: center; */
   align-items: center;
   font-size: 36px;
   font-family: "Poppins", sans-serif;
   font-weight: bold;
   margin-bottom: 25px;
   margin-top: 10px;
}

.FAQSectionTitleText {
   /* color: #244ef4; */
   font-family: Poppins;
   font-size: 34px;
   /* font-style: italic; */
   font-weight: 700;
   line-height: normal;
}


.FAQSectionBottomContainer {
   /* border: 1px solid blue; */
   /* height: 70%; */
   width: 87%;
}



.MainFAQContentSection{
   display: flex;
   justify-content: space-between;
   width:87%;
}

.LeftSideFAQSection{
   width: 50%;
   padding-left: 0px;
   padding-top: 20px;
   padding-bottom: 20px;
   padding-right: 10px;
}

.RightSideFAQSection{
   width: 50%;
   padding-left: 20px;
   padding-top: 20px;
   padding-bottom: 20px;
   padding-left: 10px;
}
.AccordianHeaderTitle{
   /* font-size: 16px !important;
   color: #000 !important;
   font-family: "Poppins", sans-serif !important;
   font-weight: bolder !important;
   color: #000; */
   font-family: "Poppins", sans-serif !important;
   font-size: 10px;
   font-style: normal;
   font-weight: normal;
   line-height: 24px; 
   letter-spacing: 0.15px;

}

 
 .AccordianHeaderTitle.bold {
   font-weight: bold;
 }

.AccordianDescTxt{
   font-size: 13px !important;
   color: #000 !important;
   font-family: "Poppins", sans-serif !important;
   font-weight: normal !important;
}

.MainAccordianContent{
   margin-bottom: 20px !important;
   border: none !important;
   outline: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
   border-top-left-radius: 13px;
   border-top-right-radius: 13px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {

   border-top-left-radius: 13px;
   border-top-right-radius: 13px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-radius: 12px;
}

.css-ahj2mt-MuiTypography-root {
   font-size: 13px;
   
   }


.FAQBannerImage{
   width: 100%;
   object-fit: cover;
}


.load-more-button {
   background-color: #eceff4;
   color: #000;
   font-size: 12px;
   border: none;
   border-radius: 8px;
   padding: 5px 5px;
   cursor: pointer;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   transition: background-color 0.3s ease;
   width: 187px;
   font-family: 'Poppins','sans-serif';
   margin-bottom: 60px;
 }
 
 .load-more-button:hover {
   background-color: #dfe4ea; /* Background color on hover */
 }
 
 .load-more-button:focus {
   outline: none; /* Remove default focus outline */
   background-color: #dfe4ea; /* Background color on focus */
 }
 


@media(max-width:767px){
   .LeftSideFAQSection {
       width: 95%;
       padding-left: 10px;
       padding-top: 20px;
       padding-bottom: 0px;
       padding-right: 0px;
   }

   .RightSideFAQSection{
       width: 95%;
       padding-left: 10px;
       padding-top: 0px;
       padding-bottom: 20px;
       padding-right: 0px;
   }
   .MainFAQContentSection {
       display: flex;
       justify-content: space-between;
       flex-direction: column;
   }

   .FAQSectionTitleSection {
       /* border: 1px solid red; */
       /* height: 10vh; */
       width: 80%;
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: 20px;
       font-family: 'Poppins', sans-serif;
       font-weight: bold;
       margin-bottom: 0px;
       margin-top: 10px;
   }

   .AccordianHeaderTitle {
       font-size: 12px !important;
       color: #000 !important;
       font-family: 'Poppins', sans-serif !important;
       /* font-weight: bold!important; */
   }

   .FAQSectionMainContainer {
       margin-top: 10px;
   }
}