.CarouselCardContainer {
    /* border: 1px solid red; */
    /* height: 253px; */
    /* width: 79%; */
    max-width: 304px;
    margin-left: 0px;
    background-color: white;
    box-shadow: 2px 2px 10px 1px #e2e2e2ad;
    border-radius: 20px;
    padding-bottom: 22px;
    margin-bottom: 10px;
    margin-right: 15px;
    margin-top: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 5px;
    z-index: 0;
}

.CarouselCardImageContainer {
    /* border: 2px solid yellow; */
    /* height: 76%; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: cover;
}

.ImageContainer {
    /* border: 2px solid green; */
    /* height: 100%;
    width: 99%; */
    border-radius: 10px;
    position: relative; 
    overflow: hidden;
    margin-top: 5px;
    background-image: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carouselCardImage {
    width: 300px;
    height: 184px;
    object-fit: cover;
    cursor: pointer;
    max-width: 100%; 
    max-height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
} 

.CarouselCardDetails {
    /* border: 1px solid blue; */
    /* height: 20%; */
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}

.CarouselCardName {
    margin-left: 10px;
    font-family: "Poppins", sans-serif;
    width: 80%;
    height: 30px;
    text-overflow: ellipsis;
    /* border: 1px solid red; */
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
}

.carouselCardOptions {
    width: 90%;
    padding-bottom: 15px;
}

.carouselRechargeDetails {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 400;
    color: rgb(24, 20, 13);
    font-family: 'Poppins', sans-serif;
}

.currencyCodeCarouselCard {
    margin-left: 2px;
}

.startingFromPricing {
    font-weight: bolder;
}

.carouselCardDesc {
    /* border: 1px solid red; */
    margin-left: 10px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .CarouselCardContainer {
        max-width: 304px;
        margin-left: 0px;
        background-color: white;
        box-shadow: 2px 2px 10px 1px #e2e2e2ad;
        border-radius: 20px;
        padding-bottom: 22px;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-top: 5px;
        padding-top: 5px;
        padding-left: 6px;
        padding-right: 10px;
        margin-left: 10px !important;
    }

    .carouselCardImage {
        width: auto;
        height: auto;
        object-fit: fill;
        cursor: pointer;
        max-width: 100%;
        border-radius: 10px;
        max-height: 100%;
    }
}

@media (max-width: 767px) {
    .CarouselCardContainer {
        margin-left: 0px;
        background-color: #ffffff;
        box-shadow: 2px 2px 10px 1px #e2e2e2ad;
        border-radius: 14px;
        padding-bottom: 0px;
        margin-bottom: 10px;
        margin-right: 5px;
        margin-top: 5px;
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px !important;
    }

    .ImageContainer {
        /* border: 2px solid green; */
        height: 100%;
        width: 99%;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carouselCardImage {
        width: 270px;
        height: 100px;
        object-fit: cover;
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-radius: 10px;
    }

    .CarouselCardName {
        margin-left: 0px;
        font-family: 'Poppins', sans-serif;
        width: 99%;
        height: 100%;
        text-overflow: ellipsis;
        /* border: 1px solid red; */
        overflow: hidden;
        white-space: nowrap;
        font-weight: 600;
        font-size: 12px;
        padding-bottom: 5px;
        text-align: center;
    }
}
