
.MainBannerImageContainerSecond{
    width: auto; 
    position: relative;
    text-align: center;
    overflow: hidden;
}

.BestSafariTxtPosition {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.BestSafariBannerTxtOne {
    margin: 0px;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
    margin-bottom: 3%;
}

.BestInSafariDescOne {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
}

.DubaiVisaDescOneottom{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: auto;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #282B2C;
    bottom: 130px;
}

.SafariIcnClass{
    height: 24px;
    margin-bottom: -4px;
}

.SafariLinks{
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
}

@media(max-width:767px){
    .BestSafariBannerTxtOne{
        font-size: 18px;
    }

    .BestInSafariDescOne{
        padding-left: 10px;
        padding-right: 10px;
        width: 95%;
        font-size: 8px;
    }

    .BestInSafariDescOneottom{
        bottom: 10px !important;
        width: 95% !important;
        font-size: 14px !important;
    }

    .SafariLinks{
        font-size: 14px;
    }
}
