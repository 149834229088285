/* .MainHomePageContainer{
    border: 1px solid red;
} */

.MainBannerImageContainer{
    width: 100%;
    position: relative;
    text-align: center;
}

.BckColorClass{
    background-color: #E8F8FF;
    padding-bottom: 50px;
}

.DesignTextHome{
    background: #fff;
    color: #E0A07A;
    padding: 0px 30px 0px 30px;
    border-radius: 100px;
}

.BannerButtonCls{
    padding-top: 50px;
}

.HomeBannerImage{
    width: 100%;
    background-size: cover;
    background-position: center;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 44.97%, rgba(0, 0, 0, 0) 98.5%); */
    /* opacity: 0.7; */
    /* background-color: #000; */
    transition: 0.5s all ease-in-out;
    margin-top: 40px;
    object-fit:contain;

}

.BannerTextPostion{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.BannerTextDesign{
    margin: 0px;
    font-size: 50px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    color: #fff;
    margin-bottom: 5%;
}

.BannerImageSubText{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 50%;
    font-size: 20px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    color: #fff;    
}


.MainCardSection{
    /* border: 1px solid red; */
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
}

.MainCardSectionecond{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 100px;
}

.MainCardSectioneThird{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
}

.MainFlxContact{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #E8F8FF;
    padding-top: 0px;
}

.HomeBannerButton{
    transform: translate(15%, 50%);
    /* border: 1px solid red; */
    width: 430px;
}

.HomeBannerGrpImgTxt{
    transform: translate(15%, 10%);
    width: 340px;
    /* border: 1px solid black; */
}

.HomeBannerSocialImgTxt{
    width: 400px;
    transform: translate(265%, -50%);
}

.HomeBckColor{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 10px;
    /* padding-right: 0px; */
    margin-right: 20px;
}

.HomeBnetxt{
    text-align: start;
    color: #fff;
    font-size: 14px;
}

.HomeGrpTxt{
    margin-left: 20px;
    padding-top: 3px;
    color: #fff;
    text-align: center;
}

.HmeGrpImg{
    height: 40px;
}

.SocialImgIcn{
    height: 24px;
}

.HomePgeBnerBtn{
    background-color: #fff6;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    margin-right: 15px;
    margin-top: 15px;
    transition: ease-in-out 0.4s;
}

.HomePgeBnerBtn:hover{
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border: 1px solid #fff;
}

.HomePgeBtn{
    display: flex;
    align-items: center;
}
.slidingImageWeb {
    display:block;
}

.slidingImageMob{
    display: none;
}

@media(max-width:767px){
    .BannerTextDesign{
        margin: 0px;
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: #fff;
        margin-bottom: 12%;
        margin-top: 25%;
    }

    .slidingImageWeb {
        display: none;
    }

    .slidingImageMob{
        display:block;
    }
    .MainCardSection{
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
    }

    .BannerImageSubText{
        width: 85%;
        font-size: 12px;
    }

    .BannerTextPostion{
        top: 45%;
    }

    .MainCardSectionecond{
        padding-top: 20px;
    }

    .MainCardSectioneThird{
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
    }

    .MainBannerImageContainerSecond{
        margin-bottom: 0px;
    }

    .MainBannerImageContainer{
        padding-top: 70px;
    }

    .BannerButtonCls{
        padding-top: 35px;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
    }

    .HomeBannerButton{
        display: none;
    }

    .HomePgeBtn{
        display: none;
    }
}