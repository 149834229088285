.MainWhatToContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.LeftSideTextContainer {
    width: 45%;
}

.RightSideImageContainer {
    width: 55%;
    text-align: end;
}

.WhatYouGetImage {
    width: 90%;
    object-fit: cover;
}

.TitleExperinceTxtSecThree {
    text-align: center;
    width: 100%;
}


.NumberIocnNdText {
    display: flex;
    justify-content: start;
    font-size: 19px;
    align-items: center;
}

.NumberIocnNdText p {
    font-size: 19px;
    color: #2D2D2D;
    margin: 0px;
    padding-left: 15px;
}

.NumberImgIcon {
    text-align: center;
    align-content: center;
    display: block;
}

.WhatToDoDescTxt p {
    padding-left: 40px;
    margin: 0px;
    font-weight: 500;
    padding-top: 5px;
    font-size: 15px;}

.WhatYouGetInfoPointOne {
    margin-bottom: 60px;
}


@media (max-width: 767px) {
    .LeftSideTextContainer {
        width: 95%;
    }

    .MainWhatToContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .RightSideImageContainer {
        width: 100%;
        text-align: center;
    }
    
    .WhatYouGetImage {
        width: 96%;
        object-fit: cover;
    }

    .TitleExperinceTxtSecThree h2 {
        font-size: 22px;
    }

    .NumberIocnNdText p {
        font-size: 18px;
    }

    .WhatToDoDescTxt p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .TitleExperinceTxtSecThree h2 {
        font-size: 20px;
    }

    .NumberIocnNdText p {
        font-size: 16px;
    }

    .WhatToDoDescTxt p {
        font-size: 12px;
    }
}
