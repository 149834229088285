.detailedBox {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    background-color: #ffffff; 
    color: #444444;
    position: fixed;
    top: 95px;
    /* width: 100%; */
    z-index: 1000; 
    /* padding: 40px; */ 
    box-shadow: 0px 52px 49px rgb(54 54 54 / 30%);
    /* display: grid; */
      grid-template-columns:repeat(3, 2fr);
  /* grid-gap: 1px;
  column-gap: 100px; */
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  }


  .detailedBoxContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .detailedBoxCategory {
    background-color: red;
    width: 100%;
    
    /* border: 1px solid red; */
    
  }
  
  .detailedBoxCategory h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .detailedBoxCategory ul {
    list-style-type: none;
    text-decoration: underline;
    padding: 0;
    color: rgb(0, 0, 0);
  }
  
  .detailedBoxCategory li {
    margin-bottom: 5px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(0, 0, 0);
    text-decoration: #fff;
  }
  
  .CardImageOne {
    text-decoration: none;
  }
  .detailedBoxActiveCategory {
    flex: 1;
    margin-right: 20px;
    text-decoration: none;
    text-decoration: #070707;
  }
  
  .detailedBoxActiveCategory h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .detailedBoxActiveCategory ul {
    list-style-type: none;
    padding: 0;
    text-decoration: none;
    
  }
  
  .detailedBoxActiveCategory li {
    margin-bottom: 5px;
    cursor: pointer;
    color: rgb(10, 9, 9);

  }
  
  
  @media (max-width: 767px) {
    .detailedBox {
      display: none;
    }
  }
  