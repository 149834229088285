.NumberImgIconSecnSvn{
    width: 30px;
}

.MainWhatToContainerStcnSvn {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.LeftSideTextContainerStcnSvn{
    width: 50%;
}

.RightSideImageContainerStcnSvn{
    width: 50%;
}

@media(max-width:767px){
    .MainWhatToContainerStcnSvn{
        flex-direction: column;
    }

    .LeftSideTextContainerStcnSvn{
        width: 95%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .RightSideImageContainerStcnSvn{
        width: 95%;
        padding-left: 15px;
        padding-right: 15px;
    }
}