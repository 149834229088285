.CardsCarouselMainContainer {
    /* border: 1px solid red; */
    height: 100%;
}

.CardsCarouselShopContainer {
    /* border: 1px solid blue; */
    /* height: 200vh; */
}

.CardsCarouselOnBoardingContainer {
    /* border: 1px solid green; */
}

.CardsCarouselMainContainer {
    /* border: 1px solid yellow; */
}

.CardsCarouselBannerContainer {
    /* border: 1px solid rebeccapurple; */
    display: flex;
}

.CardsCarouselCarouselContainer {
    overflow-x: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
    margin-left: 0px;
}

.CardsCarouselBannerMobileContainer {
    display: none;
    width: 100%;
}

.cardsBannerImage {
    width: 100%;
}

@media (max-width:464px) {
    .CardsCarouselBannerContainer {
        display: none;
    }

    .CardsCarouselBannerMobileContainer {
        display: flex;
    }
}
