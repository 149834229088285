

.highlightSectionImage {
    height: 56vh;
    height: 35vw;
}

.highlightsectionTitle {
    font-size: 19px;
}
    
.CruiseshighlightsSection{
    display: flex;
    flex-direction: row;
    height: auto;
}

.pip {
    margin-top: 5px;
}

.dinner-cruise {
    width: 100%;
}

@media (max-width: 799px) {
    
    .CruiseshighlightsSection{
        flex-direction: column;
        height: auto;
    }


    .highlightSectionImage {
        height: 56vh;
        height: 50vw;
    }
}

/* custom.css */
.PhoneInput {
    --PhoneInput-color--focus: transparent; /* Remove focus outline */
  }
  
  .PhoneInputInput {
    outline: none; /* Remove default outline */
  }
  
  .PhoneInputInput:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
  }
  