

.ActivityMapsection {
    position: relative;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #E8F8FF;
    margin-top: 0; /* Reset margin-top */
}


/* custom.css */
.PhoneInput {
    --PhoneInput-color--focus: transparent; /* Remove focus outline */
  }
  
  .PhoneInputInput {
    outline: none; /* Remove default outline */
  }
  
  .PhoneInputInput:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
  }
  